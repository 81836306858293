import { useEffect, useState } from 'react';
import { TabbedForm, FormTab, useRecordContext } from 'react-admin';
import { httpClient } from '../../../../config';
import { ConversionsReportsMainTab } from './components/ConversionsReportsMainTab';
import { ConversionsGoalsTab } from './components/ConversionsGoalsTab';
import { ToolbarAlwaysOnSaveButton } from '../../../helperComponents/ToolbarAlwaysOnSaveButton';

export const ConversionsReportsForm = ({ isCreate }) => {
  const record = useRecordContext();
  const [accounts, setAccounts] = useState([]);
  const [platform, setPlatform] = useState(record?.platform || null);

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = await httpClient(`/access/data`);
        const fetchedAccounts = JSON.parse(response.body).accountData.map(
          (account) => ({
            ...account,
            name: `${account.account_name} - ${account.id}`,
          })
        );
        setAccounts(fetchedAccounts);
      } catch (err) {
        console.error(err);
      }
    };

    fetchAccounts();
  }, []);

  return (
    <TabbedForm toolbar={<ToolbarAlwaysOnSaveButton />}>
      <FormTab label="Main">
        <ConversionsReportsMainTab
          isCreate={isCreate}
          platform={platform}
          setPlatform={setPlatform}
          accounts={accounts}
        />
      </FormTab>
      <FormTab label="Conversion Goals">
        <ConversionsGoalsTab record={record} />
      </FormTab>
    </TabbedForm>
  );
};
