import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {
   Create,
   DateInput,
   SimpleForm,
   TextInput,
   SelectInput,
   SelectArrayInput,
   AutocompleteArrayInput,
} from 'react-admin'
import {
   transform,
   setChoices,
   reportTypes,
   timezones,
} from '../../../../helpers'
import { Tooltip } from '@mui/material'
import { BackButton } from '../../../helperComponents/BackButton'
import { CustomPaper } from '../../../helperComponents/CustomPaper'
import { httpClient } from '../../../../config'
import { reportTypesMapToPlatform } from '../../../../helpers/constants'
import { REGENERATE_STATUS_LABELS } from '../../../../config/constants/reportConstants';
import { useUniqueGoals } from '../../../../hooks/useUniqueGoals';

export const ReportSettingsCreate = props => {
   const [accounts, setAccounts] = useState([])
   const [reportType, setReportType] = useState(null)
   const [affiliates, setAffiliates] = useState([])
   const { uniqueGoals } = useUniqueGoals();

   useEffect(() => {
      try {
         const doRequest = async () => {
            const [activeEntities, accessEntities] = await Promise.all([
               axios('/tables/active_entities/get_active_entities').then(data => data.data),
               httpClient(`/access/data`)
             ]);
 
            setAffiliates(activeEntities.affiliates)
            
            const fetchedAccounts = JSON.parse(accessEntities.body).accountData
              .map(({id, platform, account_name}) => ({id, platform, name: `${account_name} - ${id}`}))

            setAccounts(fetchedAccounts)
         }

         doRequest()
      } catch (err) {
         console.log(err)
      }
   }, [])

   const onChangeReportType = setFunc => ({target}) => setFunc(target.value)

   return (
     <>
        <BackButton path="/report_settings"/>
        <span className="h4">Create report setting</span>
        <Create {...props}
                title=" "
                transform={transform('affiliate_ids', 'internal_account_ids', 'user_access', 'goal_names')}>
           <SimpleForm>
              <TextInput source="report_name" label="Report name" required={true}/>
              <SelectInput source="timezone"
                           label="Timezone"
                           choices={setChoices(timezones)}
                           required={true}
                           emptyText="Choose timezone"
              />
              <AutocompleteArrayInput
                label="Affiliate IDs"
                source="affiliate_ids"
                choices={affiliates.map(affiliate => ({
                  id: affiliate.affiliate_id,
                  name: `${affiliate.affiliate_id} - ${affiliate.affiliate}`
                }))}
                PaperComponent={
                  (props) => <CustomPaper {...props} width="670px" elevation={8} margin="0 0 0 150px" />
                }
                fullWidth
              />
              <SelectInput
                source="status"
                choices={setChoices(REGENERATE_STATUS_LABELS)}
                required={true}
                emptyText="Choose a status"
              />
              <Tooltip title={
                 <div style={{padding: '20px', fontSize: '12px'}}>
                    <div style={{paddingBottom: '10px'}}>
                       Master - data from platform & Tune
                    </div>
                    <div style={{paddingBottom: '10px'}}>
                       Affiliate - Master without revenue
                    </div>
                    <div>
                       Network - Tune only
                    </div>
                 </div>
              } placement="right-end"
              >
                 <SelectInput source="report_type"
                              choices={setChoices(reportTypes)}
                              required={true}
                              onChange={onChangeReportType(setReportType)}
                              emptyText="Choose a report type"/>
              </Tooltip>
              <SelectArrayInput
                source="internal_account_ids"
                label="Internal Account IDs"
                choices={accounts.filter(({platform}) => {
                   return platform === reportTypesMapToPlatform.get(reportType)
                })}
                disabled={!reportType}
              />
              <DateInput source="default_start_date"/>
              <Tooltip title={
                 <div style={{padding: '20px', fontSize: '15px'}}>
                    <div>Empty field = All types of goal</div>
                 </div>}
                       placement="right-end"
              >
                 <SelectArrayInput
                   source="goal_names"
                   label="Goal names"
                   choices={setChoices(uniqueGoals)}
                 />
              </Tooltip>
           </SimpleForm>
        </Create>
     </>
   )
}
