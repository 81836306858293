import { useState } from 'react';
import { Box } from '@mui/material';
import { SubMenu } from './SubMenu';
import {
   Settings,
   Dashboard,
   LineStyle,
   List,
   SettingsApplications,
   MoveUp,
   Feed,
   Facebook,
} from '@mui/icons-material';
import {
   MenuItemLink,
   useSidebarState,
} from 'react-admin';
import { USER_ROLES } from '../config/constants/authConstants';
import { getTableAggregationPath } from '../utils/aggregatedTablesUtils';
import { checkAccess } from '../helpers/authUtils';

export const CustomMenu = ({dense = false}) => {
   const [state, setState] = useState({
      menuSettings: true,
      menuTechSettings: false,
      menuMediaData: false,
      menuRedirectLinks: false,
      menuSampleSystem: false,
   });
   const [open] = useSidebarState();

   const role = JSON.parse(localStorage.getItem('user')).role;

   const handleToggle = (menu) => {
      setState(state => ({...state, [menu]: !state[menu]}));
   };

   return (
      <Box
         sx={{
            width: open ? 280 : 50,
            marginTop: 1,
            marginBottom: 1,
            transition: theme =>
               theme.transitions.create('width', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
               }),
         }}
      >
         <MenuItemLink
            to="/"
            primaryText={role === USER_ROLES.CONTENT_MEDIA_MANAGER ? 'Home Page' : 'Actions'}
            sidebarIsOpen={open}
            leftIcon={<Dashboard/>}
            dense={dense}
         />

         {checkAccess({ role, allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.MEDIA_BUYER, USER_ROLES.MEDIA_MANAGER] }) &&
            <MenuItemLink
               to="/platform_account_settings"
               state={{_scrollToTop: true}}
               primaryText="Platform Account Settings"
               dense={dense}
               leftIcon={open ? null : <List/>}
            />
         }

         {checkAccess({
            role,
            allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.MEDIA_BUYER, USER_ROLES.MEDIA_MANAGER]
         }) ?
            <>
               <SubMenu
                  handleToggle={() => handleToggle('menuSettings')}
                  isOpen={state.menuSettings}
                  name="Statistic Reports"
                  dense={dense}
                  icon={<Settings/>}
               >

                  <MenuItemLink
                     to="/report_settings"
                     state={{_scrollToTop: true}}
                     primaryText="Reports settings"
                     dense={dense}
                     leftIcon={open ? null : <List/>}
                  />

                  {checkAccess({ role, allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.MEDIA_BUYER] }) &&
                     <>
                        <MenuItemLink
                           to="/campaign_settings"
                           state={{_scrollToTop: true}}
                           primaryText="Campaign settings"
                           dense={dense}
                           leftIcon={open ? null : <List/>}
                        />

                        <MenuItemLink
                           to="/default_google_campaign_settings"
                           state={{_scrollToTop: true}}
                           primaryText="Google default campaign settings"
                           dense={dense}
                           leftIcon={open ? null : <List/>}
                        />
                     </>
                  }

                  <MenuItemLink
                     to="/report_sponsored_settings"
                     state={{_scrollToTop: true}}
                     primaryText="Report sponsored settings"
                     dense={dense}
                     leftIcon={open ? null : <List/>}
                  />

                  {checkAccess({ role, allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.MEDIA_BUYER] }) &&
                     <>
                        <MenuItemLink
                           to="/top_brand_settings"
                           state={{_scrollToTop: true}}
                           primaryText="Top brand settings"
                           dense={dense}
                           leftIcon={open ? null : <List/>}
                        />
                     </>
                  }
               </SubMenu>
            </>
            :
            null
         }

         {checkAccess({ role, allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.MEDIA_MANAGER, USER_ROLES.MEDIA_BUYER] }) ?
            <>
               <SubMenu
                  handleToggle={() => handleToggle('menuMediaData')}
                  isOpen={state.menuMediaData}
                  name="Platform Integrations"
                  dense={dense}
                  icon={<Feed />}
               >
                  {checkAccess({ role, allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.MEDIA_MANAGER] }) &&
                     <>
                        <MenuItemLink
                           to="/media_platform_api_settings"
                           state={{_scrollToTop: true}}
                           primaryText="Media platform API settings"
                           dense={dense}
                           leftIcon={open ? null : <List/>}
                        />
                     </>
                  }
                  {checkAccess({ role, allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.MEDIA_BUYER] }) &&
                     <>
                        <MenuItemLink
                           to="/conversion_reports"
                           state={{_scrollToTop: true}}
                           primaryText="Conversion report"
                           dense={dense}
                           leftIcon={open ? null : <List/>}
                        />
                     </>
                  }
                  {checkAccess({ role, allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.MEDIA_MANAGER] }) &&
                     <>
                        <MenuItemLink
                           to={`/${getTableAggregationPath('meta_api_data')}`}
                           state={{_scrollToTop: true}}
                           primaryText="Meta API data"
                           dense={dense}
                           leftIcon={open ? null : <Facebook />}
                        />
                     </>
                  }
               </SubMenu>
            </>
            :
            null
         }

         {checkAccess({ role, allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.CONTENT_MEDIA_MANAGER] }) ?
            <SubMenu
               handleToggle={() => handleToggle('menuRedirectLinks')}
               isOpen={state.menuRedirectLinks}
               name="Bridge Link System"
               dense={dense}
               icon={<MoveUp/>}
            >
               <MenuItemLink
                  to="/articles_setup"
                  state={{_scrollToTop: true}}
                  primaryText="Articles"
                  dense={dense}
                  leftIcon={open ? null : <List/>}
               />
               <MenuItemLink
                  to="/redirect_domains"
                  state={{_scrollToTop: true}}
                  primaryText="Redirect Domains"
                  dense={dense}
                  leftIcon={open ? null : <List/>}
               />
               <MenuItemLink
                  to="/redirect_links"
                  state={{_scrollToTop: true}}
                  primaryText="Redirect Links"
                  dense={dense}
                  leftIcon={open ? null : <List/>}
               />
            </SubMenu>
            :
            null
         }
         {checkAccess({
            role,
            allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.CONTENT_MEDIA_MANAGER, USER_ROLES.AFFILIATE_MANAGER]
         }) ?
            <SubMenu
               handleToggle={() => handleToggle('menuSampleSystem')}
               isOpen={state.menuSampleSystem}
               name="Sample Mgmt System"
               dense={dense}
               icon={<List/>}
            >
               <MenuItemLink
                  to="/sample_system/affiliate_sample_requests"
                  state={{_scrollToTop: true}}
                  primaryText="Affiliate Sample Requests"
                  dense={dense}
                  leftIcon={open ? null : <List/>}
               />
               <MenuItemLink
                  to="/sample_system/affiliate_access"
                  state={{_scrollToTop: true}}
                  primaryText="Affiliate Access"
                  dense={dense}
                  leftIcon={open ? null : <List/>}
               />
               <MenuItemLink
                  to="/sample_system/advertiser_access"
                  state={{_scrollToTop: true}}
                  primaryText="Advertiser Access"
                  dense={dense}
                  leftIcon={open ? null : <List/>}
               />
            </SubMenu>
            :
            null
         }
         {checkAccess({ role, allowedRoles: [USER_ROLES.ADMIN] }) &&
            <>
               <SubMenu
                  handleToggle={() => handleToggle('menuTechSettings')}
                  isOpen={state.menuTechSettings}
                  name="Admin Settings"
                  dense={dense}
                  icon={<SettingsApplications/>}
               >
                  <MenuItemLink
                     to="/cron_settings"
                     state={{_scrollToTop: true}}
                     primaryText="Cron settings"
                     dense={dense}
                     leftIcon={open ? null : <List/>}
                  />
                  <MenuItemLink
                     to="/user_settings"
                     state={{_scrollToTop: true}}
                     primaryText="User settings"
                     dense={dense}
                     leftIcon={open ? null : <List/>}
                  />
               </SubMenu>
            </>
         }

         {checkAccess({
            role,
            allowedRoles: [
               USER_ROLES.ADMIN,
               USER_ROLES.MEDIA_BUYER,
               USER_ROLES.AFFILIATE,
               USER_ROLES.AFFILIATE_MANAGER,
               USER_ROLES.MEDIA_MANAGER
            ] 
         }) ? <MenuItemLink
               to="/action_logs"
               primaryText="Action logs"
               sidebarIsOpen={open}
               leftIcon={<LineStyle/>}
               dense={dense}
            />
            : null
         }
      </Box>
   );
};