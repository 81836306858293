import { httpClient } from '../../config';

export const fetchUniqueGoals = async () => {
  try {
    const { json } = await httpClient('/tables/active_entities/unique_goals');
    return json;
  } catch (error) {
    console.error('Failed to fetch goals data:', error);
    throw error;
  }
};
