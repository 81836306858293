import { TextInput, SelectInput } from 'react-admin';
import { platforms } from '../../../../../helpers/constants';

export const ConversionsReportsMainTab = ({ isCreate, platform, setPlatform, accounts }) => {
   const handlePlatformChange = ({ target }) => {
      setPlatform(target.value);
   };

   return (
      <>
         {!isCreate && <TextInput source="id" label="ID" />}
         <TextInput source="report_name" label="Report name" required={true} />
         <SelectInput
            source="platform"
            label="Platform"
            required={true}
            onChange={handlePlatformChange}
            choices={platforms
               .filter(platform => platform.conversion)
               .map(platform => ({ id: platform.id, name: platform.name }))}
         />
         <TextInput
            source="affiliate_ids"
            label="Affiliate IDs"
            fullWidth={true}
            helperText="Please enter values separating them with commas"
         />
         <SelectInput
            source="internal_account_id"
            label="Internal Account ID"
            choices={platform ? accounts.filter(account => account.platform === platform) : accounts}
         />
      </>
   );
};
