import { Create } from 'react-admin';
import { BackButton } from '../../../helperComponents/BackButton';
import { transform } from '../../../../helpers';
import { ConversionsReportsForm } from './ConversionsReports.Form';

export const ConversionsReportsCreate = (props) => (
  <>
    <BackButton path="/conversion_reports" />
    <span className="h4">Create Conversions report setting</span>
    <Create {...props} title=" " transform={transform('affiliate_ids')}>
      <ConversionsReportsForm isCreate={true} />
    </Create>
  </>
);
