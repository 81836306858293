import { useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import { Box, Typography, Checkbox, TextField, FormControlLabel } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { apiUrl } from '../../../../../config';
import { fetchConversionGoals, updateFormState } from "../../../../../utils/reports/conversionGoalsUtils";

export const ConversionsGoalsTab = ({ record, isCreate }) => {
  const { setValue } = useFormContext();
  const [goalNames, setGoalNames] = useState([]);
  const [selectedGoals, setSelectedGoals] = useState({});
  const [goalModifications, setGoalModifications] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const notify = useNotify();

  useEffect(() => {
    const fetchGoals = async () => {
      try {
        const {
          goalNames,
          selectedGoals,
          goalModifications
        } = await fetchConversionGoals({ recordId: record?.id, isCreate, apiUrl });

        setGoalNames(goalNames);
        setSelectedGoals(selectedGoals);
        setGoalModifications(goalModifications);
        updateFormState({ setValue, selectedGoals, goalModifications, goalNames });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchGoals();
  }, [record, isCreate, notify, setValue]);

  const handleCheckboxChange = (goalName) => {
    const updatedSelectedGoals = { ...selectedGoals, [goalName]: !selectedGoals[goalName] };
    setSelectedGoals(updatedSelectedGoals);
    updateFormState({ setValue, selectedGoals: updatedSelectedGoals, goalModifications, goalNames });
  };

  const handleTextChange = (goalName, val) => {
    const updatedModifications = { ...goalModifications, [goalName]: val };
    setGoalModifications(updatedModifications);
    updateFormState({ setValue, selectedGoals, goalModifications: updatedModifications, goalNames });
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box>
      <Typography variant="h5" fontWeight="bold">Add Conversion Goals</Typography>
      <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap={2} p={2}>
        <Typography variant="h6" noWrap>Select Goals</Typography>
        <Typography variant="h6" noWrap>Modify Goal Name</Typography>
      </Box>
      {goalNames.map((goalName) => (
        <Box key={goalName} display="grid" gridTemplateColumns="1fr 1fr" columnGap={2} alignItems="center" my={1}>
          <FormControlLabel
            control={<Checkbox checked={selectedGoals[goalName]} onChange={() => handleCheckboxChange(goalName)} />}
            label={goalName}
          />
          <TextField
            label="Modify"
            value={goalModifications[goalName] || ""}
            onChange={(e) => handleTextChange(goalName, e.target.value)}
            fullWidth
          />
        </Box>
      ))}
    </Box>
  );
};
