import { useEffect, useState } from 'react';
import { fetchUniqueGoals } from '../utils/loaders/goalApi';

export const useUniqueGoals = () => {
  const [uniqueGoals, setUniqueGoals] = useState([]);

  useEffect(() => {
    const loadUniqueGoals = async () => {
      try {
        const data = await fetchUniqueGoals();
        setUniqueGoals(data);
      } catch (error) {
        console.error('Error loading brands:', error);
      }
    };

    loadUniqueGoals();
  }, []);

  return { uniqueGoals };
};
