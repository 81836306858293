export const VersionHistoryPage = () => {
   return (
      <div className="rounded shadow m-5 p-5">
         <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <h3>Version History</h3>
         </div>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v14.20.0 - <span className="version-date">13/02/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Create a script to delete Conversion API old records: events - 3 months, clicks - 1 month</li>
               <li>Creation of report setting - support all types of goals (dynamic goals in the reports)</li>
               <li>Reorganize side navigation</li>
               <li>Provide access to Report Sponsored Settings for the Media Manager role</li>
            </ul>
            <h5>Fixes</h5>
            <ul>
               <li>Prevent the issue of stuck event sending to the Meta API</li>
               <li>Fix "Event Timestamp Too Old" issue in Meta API</li>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v14.19.0 - <span className="version-date">30/01/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Conversion API Historical Data enhancements</li>
               <ul>
                  <li>Add "Event time converted" column to Meta API List view</li>
                  <li>Add filter to Meta API List view to see historical data only</li>
                  <li>Change media platform api settings label to “Convert events time within last 7 days (off - Collect to Analytics only, on - Convert and send to FB)”</li>
                  <ul>
                     <li>Make this button appear only in 2 cases:</li>
                     <ul>
                        <li>If there is historical data that wasn't sent for this FB pixel</li>
                        <li>When setup the custom time for the pixel</li>
                     </ul>
                  </ul>
                  <li>Add filter by Account ID to Media Platform API settings (as a separate filter and in a search field)</li>
                  <li>Add multiple selection for the Account ID separate filter</li>
                  <li>Historical data: when all brands are processed for a setting, show a message that collection has been completed and ready to be synced</li>
                  <li>Add filter to Meta API data for pending events (the events that were not sent to Meta yet)</li>
                  <li>Meta Aggregation view:</li>
                  <ul>
                     <li>Add Brand ID+Brand Name</li>
                     <li>Show “send all”, “only Tune”, “only TrackCB”, “only Clicks” in Data source column</li>
                     <li>Add domain path, event source url columns (aggregated and non-aggregated view)</li>
                  </ul>
               </ul>
               <li>Conversion report - new goals control</li>
            </ul>
            <h5>Fixes</h5>
            <ul>
               <li>Meta API data bugs:</li>
               <ul>
                  <li>Fix bug with ordering by Campaign ID</li>
                  <li>Fix the issue with floating data</li>
                  <li>Fix recording extra fields to action logs when making changes on media platform api settings</li>
                  <li>Fix the bug with sorting by account name/account id on media platform api settings table</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v14.18.0 - <span className="version-date">17/01/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Add Clicks to Conversion API</li>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v14.17.0 - <span className="version-date">10/01/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Sync Facebook historical data and convert to last 7 days</li>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v14.16.1 - <span className="version-date">30/12/2024</span></h4>
            <h5>Fixes</h5>
            <ul>
               <li>Resolved an issue where months in report fields were inconsistently formatted, appearing as single-digit in some fields and double-digit in others.</li>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v14.16.0 - <span className="version-date">24/12/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>YouTube reports:</li>
               <ul>
                  <li>Add a functionality to upload csv reports to youtube_ads_data</li>
                  <li>Create YouTube View in the database</li>
                  <li>Add a functionality to generate the youtube report</li>
               </ul>
            </ul>
            <h5>Fixes</h5>
            <ul>
               <li>YouTube reports:</li>
               <ul>
                  <li>"Account Name" doesn’t aggregate Youtube and Tune in the same row</li>
                  <li>HO Top Brand Sales Column shows empty values</li>
                  <li>Add Video played to 25/50/75/100% views columns</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v14.15.0 - <span className="version-date">17/12/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Meta API Data:</li>
               <ul>
                  <li>Add filter to enable showing Success/Error/No Reponse statuses for the API Response Column</li>
                  <li>Write a script to divide conversion error and application error statuses</li>
                  <li>Add the ability to filter by multiple order ids and campaign ids (separated by coma)</li>
                  <li>Add the ability to filter by multiple account names</li>
                  <li>Remove campaign name column</li>
                  <li>Change date range hover to be click</li>
                  <li>Change 'Export' button label to 'Download'</li>
               </ul>
               <li>Media platform API settings:</li>
               <ul>
                  <li>Add account name to Media platform API settings columns</li>
                  <li>Add full name of Brand in Media platform API settings</li>
                  <li>Make ID column always on</li>
               </ul>
               <li>Conversion API changes for TrackCB events:</li>
               <ul>
                  <li>After 10 attempts, if no events are received for some brands, switch the button from 'Last 7 days' to 'Last Record Time'</li>
                  <li>Show what brands are successfully updated for that period of time</li>
               </ul>
               <li>Refactor a method for inserting Tune conversions and statistics</li>
            </ul>
            <h5>Fixes</h5>
            <ul>
               <li>Meta API Data:</li>
               <ul>
                  <li>Fix the bug with ID column when changing its order</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v14.14.1 - <span className="version-date">04/12/2024</span></h4>
            <h5>Fixes</h5>
            <ul>
               <li>Refactor the database connection logic in the application to properly manage connections</li>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v14.14.0 - <span className="version-date">21/11/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Conversion API: Allow multibrand selection for 1 FB pixel on TrackCB tab</li>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v14.13.2 - <span className="version-date">20/11/2024</span></h4>
            <h5>Fixes</h5>
            <ul>
               <li>Conversion API: Fix timezone issue of tune events</li>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v14.13.1 - <span className="version-date">20/11/2024</span></h4>
            <h5>Fixes</h5>
            <ul>
               <li>Change database pool connection settings</li>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v14.13.0 - <span className="version-date">30/10/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Meta enhancements</li>
               <ul>
                  <li>Add Enable/Disable toggle to start/stop sending Tune and TrackCB data</li>
                  <li>Add a description for every Affiliate Option to clarify the filters on TrackCB tab</li>
                  <li>In Media Platform API Settings Add logs of history changes in each settings</li>
                  <li>In Media Platform API Settings Add 2 Columns&Filters of sending data Enable/Disable</li>
                  <li>In Meta API Data & Media Platform API Settings Add under the filters the Total count of rows</li>
                  <li>In Meta API Data Add a description for every column label</li>
                  <li>In Meta API Data Add Campaign name as another column</li>
                  <li>In Meta API Data Add Filter by Order ID, add partial search</li>
               </ul>
            </ul>
            <h5>Fixes</h5>
            <ul>
               <li>Meta enhancements</li>
               <ul>
                  <li>Add account to action logs for Media Platform API Settings changes</li>
                  <li>make Domain Path&Facebook API Key columns on Media Platform API Settings not default active</li>
                  <li>Account name filter: only display values with Platform = Meta</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v14.12.0 - <span className="version-date">23/10/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Meta API Data</li>
               <ul>
                  <li>Add Event Date Range Filter to Meta API Data and Action Logs</li>
                  <li>Add pixel ID search</li>
                  <li>Add multi-select to Event Name</li>
                  <li>Add custom Export to Meta API Data table</li>
                  <li>Optimize page speed/loading</li>
                  <ul>
                     <li>Remove conversion_api_responses join for count queries and for aggregated view</li>
                     <li>Create a test endpoint to check metrics</li>
                  </ul>
               </ul>
            </ul>
            <h5>Fixes</h5>
            <ul>
               <li>Meta API Data</li>
               <ul>
                  <li>Add 'apply' button to the date filter</li>
                  <li>Change button from disabled 'Prepare export' to disabled 'In progress' when it is clicked and preparing</li>
                  <li>Make column Settings ID off by default</li>
                  <li>Update label for Count to be ID on non-aggregated view</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v14.11.0 - <span className="version-date">17/09/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Google Search Terms report</li>
               <ul>
                  <li>Search Terms Report Upload Error (extra coma in numbers)</li>
               </ul>
               <li>Media Reports</li>
               <ul>
                  <li>For User Total Sale show only Purchase & Purchase CPA</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v14.10.0 - <span className="version-date">12/08/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Google Search Terms report</li>
               <ul>
                  <li>Add 2 columns: Year-Month and Year-Week</li>
                  <li>Create a script to add Year-Month and Year-Week to existing data</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v14.9.0 - <span className="version-date">07/08/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Google Search Terms report</li>
               <ul>
                  <li>Actions Page: New dropdown menu, choose Report type should be displayed next to Choose existing account</li>
                  <li>Platform account settings: Edit/Create page - add a checkbox to Google reports, "Add to Google Search Terms Report"</li>
                  <li>Database Table: Google Search Terms</li>
                  <li>Add unique row combination: day, account_id, search_term, search_keyword, search_keyword_match_type</li>
               </ul>
               <li>All reports: add Gross Clicks</li>
               <li>Add an endpoint to update Tune Stats and Tune Conversions from maximum history</li>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v14.8.0 - <span className="version-date">30/07/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Facebook Conversion API</li>
               <ul>
                  <li>Remove redundant api_response column from platform_conversion_api_data</li>
                  <li>Add pixel_id column to platform_conversion_api_data to have the actual pixel</li>
               </ul>
               <li>Meta CSV & Meta Media Master & Meta Media Affiliate changes</li>
               <ul>
                  <li>Add 2 new columns to the Meta CSV: "Adds to Cart" and "Checkouts Initiated"</li>
                  <li>Add "FB Adds to Cart" and "FB Checkouts Initiated" to Meta Media Master&Meta Media Affiliate</li>
               </ul>
               <li>All reports: add Unique Clicks</li>
               <li>Offer Other tags: update to include only tags that contain “Others”</li>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v14.7.0 - <span className="version-date">25/07/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Media Manager Role</li>
               <ul>
                  <li>Add Report Settings access to the user role permissions</li>
                  <li>Update the tooltip that describes role functionality that occurs in Edit/create page for Users in Admin section</li>
               </ul>
               <li>Meta Media Master&Meta Media Affiliate - add new columns: FB Purchases&FB Cost Per Purchase</li>
               <li>Add Offer tags to Network Reports</li>
               <ul>
                  <li>Brand Status</li>
                  <li>Ongoing Policy</li>
                  <li>Policy Change</li>
                  <li>Network Change</li>
                  <li>Other tags</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v14.6.0 - <span className="version-date">22/07/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Facebook Conversion API - optimization</li>
               <ul>
                  <li>Create a separate table for api_response column, create a script to move old data there and adjust the logic for new events</li>
                  <li>Add Current - (Last 20 minutes) for Start date TrackCB</li>
               </ul>
            </ul>
            <h5>Fixes</h5>
            <ul>
               <li>Facebook Conversion API</li>
               <ul>
                  <li>Pixel ID not sorting</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v14.5.0 - <span className="version-date">16/07/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Facebook Conversion API</li>
               <ul>
                  <li>api_response - make the popup bigger by stretching the borders</li>
                  <li>Reduce the length of most of the open text filters in Meta API data</li>
                  <li>Change Media Platform API Settings page Title to "Media Platform API Settings"</li>
                  <li>Change the delay for sending the events from 1000ms to 300ms</li>
               </ul>
            </ul>
            <h5>Fixes</h5>
            <ul>
               <li>Facebook Conversion API</li>
               <ul>
                  <li>TrackCB events: add the fix to “current date” - make it not earlier than 7 days</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v14.4.0 - <span className="version-date">09/07/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>New role - Media Manager</li>
               <ul>
                  <li>Access to Actions, Platform Account Settings, Media Platform API Settings, Media Data, Action logs</li>
               </ul>
               <li>Facebook Conversion API</li>
               <ul>
                  <li>Adjustments for: exclude specific affiliates in TrackCB settings&TrackCB without Affiliates</li>
                  <li>Append value for event_source_url with Order ID: add at the end of the source URL the order ID</li>
                  <li>Meta API Data UI changes</li>
                  <ul>
                     <li>Add Order ID as column (from API Response), make searchable</li>
                     <li>Make Data Source filter static (by search), make the width of the filter to match the size of the values</li>
                     <li>Add Event_ID column, make searchable</li>
                     <li>For TrackCB, add whatever data is available on displayed rows: Internal Account ID, Account ID, Account Name, Campaign ID, Transaction ID</li>
                     <li>API Response popup: make more readable</li>
                     <li>Add Meta Data API Documentation button</li>
                  </ul>
                  <li>Media platform API Settings UI changes</li>
                  <ul>
                     <li>Add Meta Data API Documentation button</li>
                  </ul>
               </ul>
            </ul>
            <h5>Fixes</h5>
            <ul>
               <li>Google media master - some keywords not syncing with Tune data</li>
               <li>Facebook Conversion API</li>
               <ul>
                  <li>Meta API Data: fix sorting count column in aggregate view</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v14.3.0 - <span className="version-date">04/07/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Facebook Conversion API</li>
               <ul>
                  <li>Add Event ID</li>
                  <li>TrackCB format is VID-TID-CustomerID-OrderID</li>
                  <li>Tune format is TID-OrderID (without brand name)</li>
                  <li>Make UI for Event ID same as External ID and Custom Data Order ID</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v14.2.0 - <span className="version-date">02/07/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Facebook Conversion API</li>
               <ul>
                  <li>Exclude specific affiliates in TrackCB settings</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v14.1.0 - <span className="version-date">01/07/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Facebook Conversion API</li>
               <ul>
                  <li>Add option on TrackCB settings to send only TrackCB data (without Tune)</li>
               </ul>
            </ul>
            <h5>Fixes</h5>
            <ul>
               <li>Facebook Conversion API</li>
               <ul>
                  <li>Pixel sync past 7 days, not working Purchase</li>
                  <li>Fix order ID on Tune to remove brand name (everything before "-" should be removed)</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v14.0.0 - <span className="version-date">18/06/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Facebook Conversion API</li>
               <ul>
                  <li>Additional params: External ID and Order ID</li>
                  <li>Control for Tune/TrackCB additional parameters</li>
                  <li>Default Country (Media Platform API Settings)</li>
                  <li>Generate FBP for TrackCB events</li>
                  <li>Aggregation by PixelID</li>
                  <li>Autocomplete for Brands</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v13.0.0 - <span className="version-date">11/06/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Facebook Conversion API - All Events</li>
               <ul>
                  <li>Create one account ID with different pixels</li>
                  <li>Process Tune events: the ability to choose All or Specific Affiliate, All Tune events or a Specific event</li>
                  <li>Process TrackCB events</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v12.3.0 - <span className="version-date">27/05/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Give Media Buyers access to Platform Account settings automatically</li>
               <li>Optimize report 'update for 7 days' time</li>
            </ul>
            <h5>Fixes</h5>
            <ul>
               <li>Fix the bug with setting New/Return conversion to the event</li>
               <li>Fix the bug with incorrect Google clicks, cost, and impressions numbers when updating reports for 7 days</li>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v12.2.0 - <span className="version-date">21/05/2024</span></h4>
            <h5>Fixes</h5>
            <ul>
               <li>Change regenerate report to only update last 7 days</li>
               <ul>
                  <li>Added new status: status 1 - regenerate full report, status 2 - update last 7 days</li>
                  <li>Create cron that will update whole report monthly: regenerateAllReports</li>
                  <li>Additional regenerate report should be available for Admin users</li>
                  <li>Change Default rows per page to 25 for Report Settings page</li>
                  <li>For selecting rows, change 'set status -1' to 'cancel regenerate'</li>
                  <li>On Edit/Create, status field, change -1,1,2 to 'don't regenerate', 'regenerate entire report', 'update last 7 days'</li>
                  <li>Add new label to action logs: Regenerate report (7 days)</li>
               </ul>
               <li>UI changes</li>
               <ul>
                  <li>Add count to Meta API Data</li>
                  <li>Move Action Logs below Admin Settings</li>
                  <li>Add New, Media Data Section: Meta API Data will be in this section and removed from Media Settings section</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v12.1.0 - <span className="version-date">20/05/2024</span></h4>
            <h5>Fixes</h5>
            <ul>
               <li>Corrupted user_financial_data table/Connection terminated issue</li>
               <ul>
                  <li>Create a new getTuneData function to retrieve Tune data, adding a 1-second delay for each request to Tune</li>
                  <li>Refactor the logic for truncating the user_financial_data table: separate the insert and select operations into distinct queries and write the data in batches</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v12.0.0 - <span className="version-date">15/05/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Meta API Data</li>
               <ul>
                  <li>New table, Meta API Data: underneath Media Platform API settings</li>
                  <li>Columns: ID, Internal account ID, Account Name, Transaction ID, Campaign ID,
                     Event Time, Event Name, Value, API Response
                  </li>
                  <li>Make the table aggregated by default</li>
                  <li>For aggregate view, only display account ID (+ account Name)</li>
                  <li>Add aggregate by selector, default by Account Name, option for none</li>
                  <li>Customizable columns feature</li>
                  <li>Add Payout Percentage column to the right of Value column</li>
               </ul>
               <li>Media Platform API Settings</li>
               <ul>
                  <li>Add field, payout percentage, decimal values</li>
                  <li>Default value is 1</li>
                  <li>Default column order is after Tune Purchase CPA column, selectable to display from Columns menu</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v11.6.0 - <span className="version-date">07/05/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Media Platform API Settings</li>
               <ul>
                  <li>Add default-off columns: Action Source, Tune Purchase, and Tune Purchase CPA</li>
                  <li>Add possibility to manage columns on Media Platform API Settings list page</li>
                  <li>Add horizontal scroll</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v11.5.0 - <span className="version-date">30/04/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Actions Page</li>
               <ul>
                  <li>Update report names</li>
                  <ul>
                     <li>Update the meta_ads_data account_name to the name found in the Platform Account Settings by account_id</li>
                     <li>Update Campaign Name/ID and Ad Set Name/ID to the most recent Name/ID for each type</li>
                     <li>Update the functionality to not regenerate any report after the user clicks on the 'Update report names' button</li>
                  </ul>
                  <li>Add "Refresh Active Affiliates & Advertisers & Offers" button to Actions page, move it from Advertiser Access page</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v11.4.1 - <span className="version-date">25/04/2024</span></h4>
            <h5>Fixes</h5>
            <ul>
               <li>Articles Setup</li>
               <ul>
                  <li>The redirect domains field is showing only the first 10 domains</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v11.4.0 - <span className="version-date">24/04/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Platform Conversions API</li>
               <ul>
                  <li>Add security for the FB conversion API plugin</li>
                  <li>Add hashing request body data and pass that in the x-hmac header</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v11.3.0 - <span className="version-date">18/04/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Meta Media Affiliate report</li>
               <ul>
                  <li>Create report_meta_affiliate_aggr view based on 3 tables: tune_stats, tune_conversions, meta_ads_data</li>
                  <li>Add logic to regenerate Meta Media Affiliate report type on API side</li>
                  <li>Write query to create a report with type Meta Media Affiliate</li>
               </ul>
            </ul>
            <h5>Fixes</h5>
            <ul>
               <li>Add a delay of 0.2 seconds for each request to Tune</li>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v11.2.0 - <span className="version-date">15/04/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Meta Media Master Report updates</li>
               <ul>
                  <li>Make Meta Cost equal to amount_spent from meta ads data, not cpc</li>
                  <li>Add new columns Ad, Account Name, Ad Set Name, and Ad Name</li>
                  <li>Apply the same rules for Tune rows in Account Name as for Campaign Name</li>
               </ul>
               <li>Media Platform API Settings</li>
               <ul>
                  <li>Add a new functionality to define how the Tune event Purchase\Purchase CPA is sent to the FB pixel event</li>
               </ul>
            </ul>
            <h5>Fixes</h5>
            <ul>
               <li>Fix Purchase CPA columns in Meta type report</li>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v11.1.0 - <span className="version-date">10/04/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Platform Conversions API</li>
               <ul>
                  <li>Add a new rule:</li>
                  <li>to find account id, use aff_sub (pixel_id) if account id can't be found by aff_sub_3 (campaign id)</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v11.0.0 - <span className="version-date">09/04/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Platform Conversions API</li>
               <ul>
                  <li>Add 3 crons: getLastTuneData, getCustomerData, sendMetaApiData</li>
                  <li>Add new table: platform_conversions_api_data</li>
                  <li>Crons prepare and send data to proxy</li>
                  <li>Data is taken from platform_conversions_api_data and media_platform_api_settings tables</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v10.0.0 - <span className="version-date">03/04/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Media Platform API Settings</li>
               <ul>
                  <li>Add new table Media Platform API Settings</li>
                  <li>Add Edit, Create rows for Media Platform API Settings</li>
                  <li>Add filters and search by ID, Internal Account ID, Affiliate ID, Domain, Domain Path, Facebook Pixel ID</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v9.5.0 - <span className="version-date">01/04/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Regenerate Meta Media Master type reports</li>
               <ul>
                  <li>Add new columns to Meta reports: Reach, Frequency, Link Clicks</li>
                  <li>Rename Meta Clicks to All Clicks</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v9.4.0 - <span className="version-date">01/04/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Regenerate Meta Media Master type reports</li>
               <ul>
                  <li>Add support for the GMT+8 timezone</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v9.3.0 - <span className="version-date">28/03/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Download Meta Conv report</li>
               <ul>
                  <li>Change payout value to 1.00 if it is 0</li>
                  <li>Convert Goal Name value to Event Name value that Meta expects</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v9.2.1 - <span className="version-date">25/03/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Meta .csv upload</li>
               <ul>
                  <li>Automatically set 0 to empty columns with type number</li>
                  <li>Adjust order of columns in the meta csv upload, remove ads column</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v9.2.0 - <span className="version-date">22/03/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Meta Conversion report</li>
               <ul>
                  <li>Add Hashed customer phone and email to Meta Conversion report</li>
                  <li>Filter conversion report by Account</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v9.1.0 - <span className="version-date">18/03/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>General</li>
               <ul>
                  <li>Add timestamps to logs</li>
               </ul>
               <li>Download Meta conversions report</li>
               <ul>
                  <li>Add country code to the report</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v9.0.0 - <span className="version-date">14/03/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Meta platform</li>
               <ul>
                  <li>Upload Meta .csv</li>
                  <ul>
                     <li>Write queries to create new meta_ads_data table</li>
                     <li>Add possibility to choose account</li>
                     <li>Chosen account_name is set to account_name in meta_ads_data</li>
                     <li>Add index to identify unique rows: time + campaign_id + ad_set_id + ad_id</li>
                     <li>Add meta_campaign_ids field to report_sponsored_settings</li>
                     <li>Refactor&move report types to constants</li>
                  </ul>
                  <li>Create Meta Media Master report</li>
                  <ul>
                     <li>Create report_meta_aggr view based on 3 tables: tune_stats, tune_conversions, meta_ads_data</li>
                     <li>Add logic to regenerate Meta Media Master report type on API side</li>
                     <li>Write query to create a report with type Meta Media Master</li>
                     <li>Refactor&move report types to constants</li>
                  </ul>
                  <li>Download raw Meta data</li>
                  <ul>
                     <li>Add logic to handle the downloading of raw Meta data</li>
                  </ul>
                  <li>Download conversion Meta data</li>
                  <ul>
                     <li>Add logic to handle the downloading of conversion Meta data</li>
                     <li>Refactor download conversion data controller to make it easier to use</li>
                     <li>Columns for Meta conversion report:</li>
                     <ul>
                        <li>Empty:</li>
                        <ul>
                           <li>email,email,email,phone,phone,phone,madid,fn,ln,zip,ct,st,country,dob,doby,gen,age</li>
                        </ul>
                        <li>Not empty, data taken from tune_conversions_data</li>
                        <ul>
                           <li>event_name - goal_name</li>
                           <li>event_time - datetime</li>
                           <li>value - payout</li>
                           <li>currency - currency</li>
                        </ul>
                     </ul>
                  </ul>
                  <li>Report Sponsored Settings</li>
                  <ul>
                     <li>Update UI to divide settings for rows with present/absent Account Id</li>
                  </ul>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v8.0 - <span className="version-date">28/02/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>General</li>
               <ul>
                  <li>Move Admin settings menu to the bottom of the left panel</li>
               </ul>
               <li>Bridge Link System</li>
               <ul>
                  <li>Add https:// to all Redirect Links</li>
                  <li>Add copy button to left of Test button - copy redirect link with https:// included</li>
                  <li>Add https:// to Redirect Link column and right panel editor field</li>
                  <li>Add https:// to redirect links on Redirect Link tab</li>
                  <li>Make search by redirect link work with https and without</li>
               </ul>
               <li>Meta platform</li>
               <ul>
                  <li>Add Meta select UI to Upload and Download report</li>
                  <li>Remove user access field from Edit/Create platform account settings</li>
               </ul>
            </ul>
            <h5>Fixes</h5>
            <ul>
               <li>Sample System</li>
               <ul>
                  <li>Affiliate Sample request, row count not showing the correct number</li>
               </ul>
               <li>Cron settings</li>
               <ul>
                  <li>Cannot change some cron jobs settings via UI</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v7.0 - <span className="version-date">23/01/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Add new columns to Network, Media and Bing reports - Purchase CPA Payout, Purchase CPA Revenue, Purchase CPA Sales Amount</li>
               <ul>
                  <li>For rows that have Goal Purchase CPA as true: values should not be empty</li>
                  <li>For rows that have Goal Purchase CPA as false: values should be empty</li>
               </ul>
               <li>New rule for New Conversion: if goal = purchase CPA then all New Conv = 1 (regardless of Transaction ID rule)</li>
               <li>Return Conversion - when above is implemented then Return Conversions will only be where Goal = Purchase and Not where Goal = Purchase CPA</li>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v6.5 - <span className="version-date">15/01/2024</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Update outdated packages of Analytics Dashboard and Sample System</li>
               <ul>
                  <li>Fix audit - outdated packages and supported versions</li>
                  <li>Set stable versions for packages to not allow them to autoupdate</li>
                  <li>Add package-lock.json file (remove it from gitingnore)</li>
               </ul>
               <li>Add new column to reports - Goal Purchase CPA</li>
               <ul>
                  <li>For rows that have Goal Purchase CPA as true:</li>
                  <li>add values (from tune Conversions data) for payout, revenue & sale amount to Report columns</li>
                  <li>set New Conv Purchase to 1 when Goal Purchase CPA is 1 and New Conv is 1 (same as for Goal Purchase)</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v6.0 - <span className="version-date">12/12/23</span></h4>
            <h5>Fixes</h5>
            <ul>
               <li>Fix: Report/Pivot Keyword match issue</li>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v5.5 - <span className="version-date">07/12/23</span></h4>
            <h5>Features</h5>
            <ul>
               <li>All Affiliate Sample Requests Page</li>
               <ul>
                  <li>Rename header to All Affiliate Sample Requests</li>
                  <li>When status = Open, admin clicks row (to affiliate request history page)</li>
                  <li>then on Affiliate Sample History page filter should = Pending</li>
                  <li>All other status’s on All affiliate page should be Filter status All on Affiliate History page</li>
               </ul>
               <li>Affiliate Sample History page</li>
               <ul>
                  <li>Remove header “Affiliate Sample Requests”</li>
                  <li>Remove extra space</li>
                  <li>Rename status Column to Request Status</li>
                  <li>Make values to be as in edit mode, Sent to Brand, or Rejected</li>
               </ul>
               <li>Advertiser/Brand page</li>
               <ul>
                  <li>When Advertiser Status = Awaiting Affiliate Marketing Content, Advertiser Action = “No Action”</li>
               </ul>
            </ul>
            <h5>Fixes</h5>
            <ul>
               <li>Fix: regenerating reports via Report settings tab resets the default date</li>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v5.0 - <span className="version-date">28/11/23</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Affiliate Sample Requests Page: add cursor to table rows</li>
               <li>Affiliate Form view, Main tab:</li>
               <ul>
                  <li>Change status label to Request Status</li>
                  <li>New dropdown Fields: Affiliate Status, Affiliate Action, Advertiser Status, Advertiser Action</li>
                  <li>Make them automated based on the existing rules of the system, if changed rules should be ignored</li>
                  <li>Add 2 notes to these fields</li>
                  <li>Approved value renamed to Sent to Brand</li>
                  <li>History Box</li>
                  <ul>
                     <li>Rename Submit Date to Affiliate Submit Date</li>
                     <li>Rename Decision Date to Request Status Decision Date</li>
                     <li>2 New timestamps: Shipment Tracking added and Advertiser Comments Added</li>
                  </ul>
               </ul>
            </ul>
            <h5>Fixes</h5>
            <ul>
               <li>Fix TabbedForm issue with tabs not working because of react-admin library update</li>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v4.5 - <span className="version-date">13/11/23</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Download raw data, implement new logic for reports download</li>
               <li>Connect websockets to project to receive updates automatically</li>
            </ul>
            <h5>Fixes</h5>
            <ul>
               <li>Download raw data, Outbrain section data timeout</li>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v4.0 - <span className="version-date">25/10/23</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Redirect to Login Page when user session timeout</li>
               <li>Bridge links, Deep link with special characters</li>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v3.9 - <span className="version-date">17/10/23</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Add ID (database) column to tables missing this column</li>
               <ul>
                  <li>Bridge Link System/Redirect Links</li>
                  <li>Sample Mgmt System/Affiliate Access</li>
                  <li>Sample Mgmt System/Advertiser Access</li>
               </ul>
               <li>Media Settings/Report Settings</li>
               <ul>
                  <li>Add delete functionality when row is selected</li>
                  <li>Original report settings should be saved, but not displayed in UI</li>
                  <li>If Delete button clicked, popup should confirm delete</li>
                  <li>Remove user access field from Edit/Create report settings</li>
                  <li>Add affiliate search in the Create/Edit Report settings</li>
               </ul>
               <li>Actions page</li>
               <ul>
                  <li>Outbrain/Section data: Existing Campaigns dropdown menu should be expanded to fit campaign name text</li>
               </ul>
               <li>Bridge Link System</li>
               <ul>
                  <li>Articles Setup, Redirect Links Setup tab</li>
                  <ul>
                     <li>Add Position 1-15 field to Popup Editor</li>
                     <li>Place new param aff_sub5=pos+number after aff_sub4 in Destination link (only if Enable Link builder is on)</li>
                     <li>Move Save button to be in line with Test button in Popup Editor</li>
                     <li>Add Position Column to be left most column on Redirect Links Setup tab</li>
                     <li>Make column order be from Left to right</li>
                     <li>Offer - Redirect link - Destination link - Position - Affiliate - Test link</li>
                     <li>Offer should be sorted by default tab table view</li>
                     <li>Move button below table, +add Redirect Link, to above table, between Article ID and table</li>
                  </ul>
                  <li>Redirect Links</li>
                  <ul>
                     <li>New column, Position (left to Offer column)</li>
                     <li>Add new filter, select of Position 1-15</li>
                     <li>Make Export in same column format as UI table</li>
                     <li>Aggregate by Article, toggle</li>
                     <ul>
                        <li>Only display button if Offer Filter is selected</li>
                        <li>If Toggle is enabled: all Articles where there are multiple Offers should display only 1 row</li>
                        <li>Clicking on row expand the display of all offers for Article</li>
                        <li>Rows per page count should display count of rows but not include count of collapsed rows</li>
                        <li>If row is expanded then each individual row should enable popup</li>
                        <li>If row number of Links is less than two then each row should enable popup</li>
                     </ul>
                  </ul>
               </ul>
            </ul>
            <h5>Fixes</h5>
            <ul>
               <li>User report access is being reset automatically</li>
               <li>Fix Sentry issue with hidden(deleted) reports when actualizeReportNames cron job is running</li>
               <li>Fix sorting(ordering) by offer and position on Redirect Links Setup tab table</li>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v3.85 - <span className="version-date">15/09/23</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Add logs to Sentry to check the time of Network reports regenerating</li>
               <li>Add logs to Container to check when user settings and access to reports are changing</li>
            </ul>
            <h5>Fixes</h5>
            <ul>
               <li>Fix Network reports filtering by Affiliates</li>
               <li>Fix floating popups issue</li>
               <li>Bridge Links System, Media Manager role - filter causing page crash</li>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v3.8 - <span className="version-date">30/08/23</span></h4>
            <h5>Features</h5>
            <ul>
               <li>Actions Page</li>
               <ul>
                  <li>Add search functionality:</li>
                  <ul>
                     <li>Google: Open Existing Account list</li>
                     <li>Outbrain, Section Data: Open Campaign list</li>
                     <li>Taboola, Sites Data: Open Campaign list</li>
                  </ul>
               </ul>
               <li>Redirect Links Config, Articles Setup, Popup Section</li>
               <ul>
                  <li>Remove Link# field, everything should move up enough to see all fields without scrolling down</li>
                  <li>Remove new line from side panel</li>
                  <li>Make redirect link update with offer and subbrand. If both fields are filled - show subbrand in redirect link</li>
               </ul>
               <li>Redirect Links Config, Articles, Article Setup, Redirect Links Setup tab</li>
               <ul>
                  <li>Remove Link # column</li>
               </ul>
               <li>Redirect Links Config, Articles, Article Setup, Main tab</li>
               <ul>
                  <li>Article URL field should color dark grey the part that cannot be edited</li>
                  <li>Make Article URL field bigger</li>
               </ul>
               <li>Redirect Links Config, Redirect Links page</li>
               <ul>
                  <li>Should not display any “deleted” redirect links or redirect links contained in “deleted” articles</li>
               </ul>
               <li>Media Settings/Platform Account settings</li>
               <ul>
                  <li>Remove column, user access names</li>
               </ul>
            </ul>
            <h5>Fixes</h5>
            <ul>
               <li>Add check when IP address is not added to Tune, not to delete Offers and Affiliates</li>
               <li>Sample Mgmt system: Advertiser access, Refresh Active Advertisers list: error occurred on refresh</li>
               <li>Redirect Links page: Article ID column should be the one created by user in article setup Main tab, not database ID</li>
               <li>Redirect Links page: fix count of rows</li>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v3.7</h4>
            <h5>Features</h5>
            <ul>
               <li>General</li>
               <ul>
                  <li>Add 100 & 200 to rows per page</li>
                  <li>Analytics Menu - change name from Redirect Links Config to Bridge Link System</li>
               </ul>
               <li>Redirect Links Config, Articles Setup, Popup Section</li>
               <ul>
                  <li>Add toggle for Destination Link</li>
                  <li>If toggle is enabled, Destination link is changed to template once another field is changed</li>
                  <li>If toggle is disabled, Destination link is editable and changes in another fields will not affect it</li>
                  <li>Redirect Link cannot be the same as Article URL, provide info popup on save to inform</li>
                  <li>Redirect Link cannot be reused, provide info popup on save to inform</li>
                  <li>Toggle button</li>
                  <ul>
                     <li>Change toggle text From “Enable Destination Link Builder (This will reset Destination Link)”</li>
                     <li>To “Enable Link Builder (This will reset Redirect Link & Destination Link)”</li>
                  </ul>
                  <li>Toggle should control Redirect link automation as well as Destination link</li>
                  <li>Toggle should have no affect on Deep link functionality</li>
                  <li>If Toggle button is enabled</li>
                  <ul>
                     <li>When redirect link subfolder is updated manually and saved, it should replace Aff_sub4 value with the modified Redirect link subfolder</li>
                     <li>Sub-brand field should updated both Redirect link & Aff_sub4 with [RedirectLink]/[Sub-brand][ArticleID], Aff_sub4=[Sub-brand][ArticleID]</li>
                  </ul>
                  <li>Offer ID & Affiliate ID Menu dropdown (both menus)</li>
                  <ul>
                     <li>Change Names to Offer & Affiliate</li>
                     <li>Sort should be alphabetical: Format of entry should not be changes (e.g. 164 | King Kanine - Store)</li>
                     <li>Enable search for these dropdown menus</li>
                  </ul>
                  <li>Destination link, order of params should change: offer_id=[value] and aff_id=[value] should come first</li>
                  <li>4 offers need to disable deep links field, and display message: Deep links not supported for this Offer</li>
                  <ul>
                     <li>4 - CBDfx</li>
                     <li>2 - CBDPure</li>
                     <li>6 - Formula Swiss</li>
                     <li>112 - CBDMD</li>
                     <li>218 - Apollo</li>
                  </ul>
                  <li>Save button should not close the right panel</li>
               </ul>
               <li>Bridge Link System</li>
               <ul>
                  <li>Pass any params from Redirect link to Destination link</li>
               </ul>
               <li>Redirect Links Config, Articles Page</li>
               <ul>
                  <li>Display Article ID column to left of Article URL Column</li>
                  <li>Enable delete articles</li>
               </ul>
               <li>Redirect Links Config, Articles, Article Setup, Redirect Links Setup tab</li>
               <ul>
                  <li>Offer ID - Change column name to Offer, and display Offer ID & Offer Name</li>
                  <li>Offer name should not include (store)</li>
                  <li>Affiliate ID column - Change column name to Affiliate and display Affiliate ID & Affiliate Name</li>
                  <li>New Column, Test, placed most right table</li>
                  <li>Enable test button within each row</li>
                  <li>New Column, Link #, placed most left on table</li>
                  <li>Redirect Links Setup popup editor</li>
                  <ul>
                     <li>Create field, link #, with autogenerated ID (serial redirect link #) e.g. 1, 2, 3, 4</li>
                     <li>Make Link # editable</li>
                  </ul>
                  <li>Enable select and delete Redirect links</li>
                  <ul>
                     <li>Left most column should be select or multi-select</li>
                     <li>When one or more rows are selected, delete button should be enable</li>
                  </ul>
                  <li>Highlight redirect link row when side- popup editor is open</li>
                  <li>Header - Resize Publisher ID, Article ID, Article URL Header text</li>
               </ul>
               <li>Redirect Links Config, Redirect Links page</li>
               <ul>
                  <li>Hide ID column</li>
                  <li>Add columns - Offer Name, to right of Offer ID</li>
                  <li>Offer should still be able to filter or search by offer ID and or Offer Name</li>
                  <li>Add columns - Affiliate Name, to right of Affiliate ID</li>
                  <li>Affiliate should still be able to filter or search by Affiliate ID and or Affiliate name</li>
                  <li>Remove Columns: Domain Name Active</li>
                  <li>Reorder Columns: ID Column, Article URL, Redirect Link, Destination Link, Offer, Affiliate , Publish Date</li>
                  <li>Clicking anywhere on table row except Article URL, should open side panel link editor</li>
                  <ul>
                     <li>same as redirect article setup</li>
                     <li>If clicking only on Article URL, then go to Articles, Article Setup page</li>
                     <li>add page instructions text underneath Header label:</li>
                     <li>"Click Article URL to open Article setup page, or anywhere else on row to edit links from this page."</li>
                  </ul>
                  <li>Add Published? Filter, (yes/no)</li>
                  <li>Add article ID column to left of Article URL column</li>
                  <li>Article URL values should go to URL in new tab</li>
                  <li>Article ID should be link to open article setup page: make look like a link</li>
                  <li>New instructions: Click Article ID to open Article setup page, Article URL will open article in a new tab, click anywhere else on row to edit links from this page.</li>
               </ul>
               <li>Redirect Links Config, Articles, Article Setup, Main Tab</li>
               <ul>
                  <li>Article Domain field - This should not be a dropdown, not editable</li>
                  <li>This should only be a dropdown when coming from Create button on Articles main page</li>
                  <li>Article ID should not be required</li>
               </ul>
               <li>Redirect Links Config, Articles, Article Setup, Create New</li>
               <ul>
                  <li>Article Domain dropdown - Create Article Domain should be bold text</li>
               </ul>
               <li>Report Settings Table</li>
               <ul>
                  <li>Remove User Names column</li>
               </ul>
            </ul>
            <h5>Fixes</h5>
            <ul>
               <li>Special characters in Offer ID causing links to break:</li>
               <li>When creating Redirect link or Destination link any offers with special characters, remove this from the value placed in both links for all rules</li>
               <li>Offer ID 169 | Tommy Chong Exclusive not generating populating  redirect link or destination link</li>
               <li>Redirect Links Config: Menu gets cut off when Media settings menu is open - low priority (admin only)</li>
               <li>Redirect Links Page: Table doesn’t resize well when left panel open in desktop (low priority issue)</li>
               <li>Redirect Links Setup Page</li>
               <ul>
                  <li>New subfolder being created on Redirect Link, when Enable link builder is on, select offer</li>
                  <li>Expected results for redirect domain: redirectdomain + /Brand+Article ID</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v3.6</h4>
            <h5>Features</h5>
            <ul>
               <li>Sample Mngm System</li>
               <ul>
                  <li>Affiliate Request History Page</li>
                  <ul>
                     <li>Rename column, Approved/Rejected, to Status, Default values for this row are pending</li>
                     <li>Add Status Filter to page, default should be pending</li>
                     <li>Submit Date, Decision Date, Remove seconds from timestamp display</li>
                     <li>Rename column, Tracking #, to Shipment Tracking Link</li>
                     <li>Add icons instead of V and X</li>
                     <li>Add affiliate action for Sample Requests</li>
                  </ul>
                  <li>View/Edit Sample Request page</li>
                  <ul>
                     <li>Main tab: change tracking link field label from “Tracking Link” to Shipment</li>
                     <li>Main tab: Approved/Rejected, default value should be Pending</li>
                     <li>Address/Contact Info tab: Territory field label should say State if country is US</li>
                     <li>Update Table pagination</li>
                     <li>Add icons instead of true and false</li>
                     <li>Fix reviewNumber bug</li>
                     <li>Make requestNumber chronological, i.e. oldest should be 1, newest should be highest number</li>
                     <li>Rearrange fields address/contact info tab</li>
                     <li>Main tab: make editable Shipment Tracking Link & Advertiser comments </li>
                  </ul>
                  <li>Advertiser Access</li>
                  <ul>
                     <li>Move Refresh button to the top of the table</li>
                  </ul>
               </ul>
            </ul>
            <h5>Fixes</h5>
            <ul>
               <li>Sample Mngm System</li>
               <ul>
                  <li>Fix status filter</li>
                  <li>Fix roles permissions</li>
                  <li>Fix namings</li>
                  <li>Fix status to change to close only if there are no pending requests</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v3.5</h4>
            <h5>Features</h5>
            <ul>
               <li>Redirect Links Config</li>
               <ul>
                  <li>Articles</li>
                  <ul>
                     <li>Add 2 tabs: Main and Redirect Links Setup</li>
                     <li>Main tab</li>
                     <ul>
                        <li>Create Publisher ID and Article ID fields</li>
                        <li>Make Article URL field to automatically show url from Article Domain</li>
                        <li>Remove Create Article URL and show all previous articles urls option</li>
                     </ul>
                     <li>Redirect Links Setup tab</li>
                     <ul>
                        <li>Add table to show all created redirect articles related to specific article</li>
                        <li>Redirect Link Popup</li>
                        <ul>
                           <li>Show Article ID and Offer Name in the Redirect Link</li>
                           <li>Set default Affiliate ID to 1554</li>
                           <li>Make Destination link editable</li>
                           <li>Automatically add Offer ID, Affiliate ID and Params to Destination link</li>
                           <li>Add new Deep Link field and add it to Destination link when clink on +Add button</li>
                           <li>Add new Subbrand field and option to show it in Destination link</li>
                        </ul>
                     </ul>
                  </ul>
                  <li>Redirect Links</li>
                  <ul>
                     <li>Add Offer ID column</li>
                  </ul>
               </ul>
            </ul>
            <h5>Fixes</h5>
            <ul>
               <li>Redirect Links Config</li>
               <ul>
                  <li>Redirect Links</li>
                  <ul>
                     <li>Sizing issues in prod for large links in Destination Link field</li>
                     <li>ID, Redirect Link, Destination Link fields sorting</li>
                  </ul>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v3.4</h4>
            <h5>Features</h5>
            <ul>
               <li>Sample Mgmt System</li>
               <ul>
                  <li>Ability to edit sample request</li>
                  <li>Added filter to Open Request page: show Open requests, show Closed or show Open/Closed requests</li>
                  <li>Added filter by Affiliate</li>
                  <li>Added Column "Status" to requests page</li>
                  <li>New tables</li>
                  <ul>
                     <li>Affiliate Access</li>
                     <li>Advertiser Access</li>
                  </ul>
               </ul>
               <li>Redirect Links Config</li>
               <ul>
                  <li>Articles Setup</li>
                  <ul>
                     <li>Affiliate and Offer fields sorted by name instead of sort by id</li>
                  </ul>
               </ul>
               <li>Actions Page</li>
               <ul>
                  <li>Upload report</li>
                  <ul>
                     <li>Stop automatically regenerate reports after CSV upload</li>
                     <li>Add check for the position of Currency Code field in Google report</li>
                  </ul>
               </ul>
            </ul>
            <h5>Fixes</h5>
               <ul>
                  <li>Report regeneration time out (recurring) issue</li>
               </ul>
            <h5>Style</h5>
            <ul>
               <li>Added borders to Sample System components</li>
               <li>Changed header name to this template: *affiliate id* | *affiliate name* | Sample Requests</li>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v3.3</h4>
            <h5>Features</h5>
            <ul>
               <li>Added new menu: SampleMgmt System</li>
               <li>Added new role: 4 | Affiliate Manager</li>
               <li>New table in the menu: Open Requests</li>
            </ul>
            <h5>Fixes</h5>
            <li>Download conversions report</li>
            <ul>
               <li>Fixed custom selection of end date</li>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v3.2</h4>
            <h5>Features</h5>
            <ul>
               <li>Added 4 new columns for Google, Bing and Network reports</li>
               <ul>
                  <li>New Conv Payout</li>
                  <li>New Conv Sales Amount</li>
                  <li>New Conv Purchase</li>
                  <li>New Conv Revenue</li>
               </ul>
               <li>Added "Return Conv" and "New Conv" columns to:</li>
               <ul>
                  <li>All "Outbrain" reports</li>
                  <li>All "Taboola" reports</li>
               </ul>
               <li>Download Conversion report</li>
               <ul>
                  <li>Added shortcuts for date</li>
               </ul>
               <li>Added new cron which provides all access to Admin users</li>
            </ul>
            <h5>Fixes</h5>
            <ul>
               <li>Return and New conv values in Bing, Network and Google reports</li>
            </ul>
            <ul>
               <li>Bug with user calculations in reports (User financial data)</li>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v3.1</h4>
            <h5>Features</h5>
            <ul>
               <li>For working with redirect links there are new page: Articles</li>
               <li>To see all redirect links there are refactored page: Redirect links</li>
               <li>Created 2 new tables: active_offers and active_affiliates</li>
               <li>Shows only active Offers and Affiliates (also filters Affiliates which includes "internal")</li>
            </ul>
            <ul>
               <li>Ability to download raw data for Admins and Media Buyers</li>
               <li>Cron expression field in Cron Settings Editor is editable now</li>
            </ul>
            <ul>
               <li>New page in dashboard: Articles</li>
            </ul>
            <h5>Fixes</h5>
            <ul>
               <li>Tooltip now displays description for Content Media Manager in user settings editor</li>
               <li>Year-Week format for master reports</li>
               <li>Fixed selection from user_financial_data that affected sum of user total sales in master reports</li>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v3.0</h4>
            <h5>Features</h5>
            <ul>
               <li>New role: Content Media Manager</li>
               <li>New table: Redirect Links</li>
               <li>New table: Active offers and affiliates</li>
               <ul>
                  <li>Filters and search for all columns</li>
               </ul>
               <li>Redirect Links table</li>
               <ul>
                  <li>Ability to update active offers and affiliates</li>
               </ul>
            </ul>
            <h5>Style</h5>
            <ul>
               <li>All tables</li>
               <ul>
                  <li>Every second row in table can have different color now (in Light and Dark mode)</li>
                  <li>Search bar size fix</li>
                  <li>Header color change</li>
               </ul>
               <li>Added favicon and logo on app bar</li>
            </ul>
            <h5>Bugs</h5>
            <ul>
               <li>Taboola row data upload</li>
               <ul>
                  <li>Fix number converting (vcpm, impressions...)</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v2.0.2.4</h4>
            <h5>Features</h5>
            <ul>
               <li>All tables</li>
               <ul>
                  <li>Search bar and separate filters are using AND instead of OR now</li>
               </ul>
               <li>All master reports with Return Con and New Conv</li>
               <ul>
                  <li>Fixed logic of Return and New Conv calculations </li>
               </ul>
               <li>Bing master report</li>
               <ul>
                  <li>Changed name and added calculations for Bing Top Impressions column</li>
                  <li>Changed name and added calculations for Bing Absolute Top Impressions column</li>
               </ul>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
           <h4>AffsMedia Analytics v2.0.2.3</h4>
           <h5>Features</h5>
           <ul>
             <li>New columns in all master reports:</li>
             <ul>
               <li>Tune Conv purchase Revenue</li>
               <li>Tune Conv purchase Payout</li>
               <li>Tune Conv purchase sale amount</li>
             </ul>
           </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v2.0.2.2</h4>
            <h5>Features</h5>
            <ul>
               <li>Conversion data table</li>
               <ul>
                  <li>Fetch all conversion data from Tune</li>
               </ul>
               <li>Reports</li>
               <ul>
                  <li>Outbrain auto-update section names</li>
                  <li>Outbrain stats, Tune Conv purchase for Revenue, Payout, & Payout</li>
               </ul>
               <li>Report settings</li>
               <ul>
                  <li>Multi change report statuses</li>
               </ul>
               <li>Cron settings</li>
               <ul>
                  <li>Created Cron settings table</li>
                  <li>Added possibility to control Cron Jobs</li>
                  <li>Added UI to control Cron Jobs</li>
                  <li>Added cron descriptor</li>
               </ul>
               <li>Action logs</li>
               <ul>
                  <li>
                     Non admin user access to Action logs page
                     (see only updates with their username or any updates to reports they have access to)
                  </li>
               </ul>
            </ul>
            <h5>Bug</h5>
            <ul>
               <li>Fixed ability to save new data on certain settings tables</li>
            </ul>
         </p>
         <hr/>
         <p className="mb-3 mt-3">
            <h4>AffsMedia Analytics v2.0.2.0</h4>
            <h5>Features</h5>
            <ul>
               <li><h6>All pages</h6></li>
               <ul>
                  <li>Laptop View - font size increase</li>
                  <li>Search bar, displays searchable columns (now static)</li>
               </ul>
            </ul>
            <ul>
               <li><h6>Actions Page</h6></li>
               <ul>
                  <li>Boxed windows (makes night mode usable)</li>
               </ul>
            </ul>
            <ul>
               <li><h6>Action Logs</h6></li>
               <ul>
                  <li>Cron jobs display time range</li>
                  <li>Account ID filter</li>
                  <li>Removed bulk "Delete" button</li>
                  <li>TTC</li>
                  <ul>
                     <li>Sort functionality and Tool tip</li>
                  </ul>
                  <li>Date filters</li>
                  <ul>
                     <li>Range, can be combined with other filters</li>
                  </ul>
               </ul>
            </ul>
            <ul>
               <li><h6>Conversion Reports</h6></li>
               <ul>
                  <li>Account Name filter (multi-selection)</li>
               </ul>
            </ul>
            <ul>
               <li><h6>Report Sponsored Settings</h6></li>
               <ul>
                  <li>Google Campaign IDs tooltip to display all values</li>
               </ul>
            </ul>
            <ul>
               <li><h6>Report Settings</h6></li>
               <ul>
                  <li>Status msg, displayed when not idle</li>
               </ul>
            </ul>
            <ul>
               <li><h6>Increased Fonts and Sizes </h6></li>
            </ul>
            <h5>Bugs & Fixes</h5>
            <ul>
               <li><h6>Taboola CSV refactor</h6></li>
            </ul>
            <ul>
               <li><h6>Bing CSV and Overwrite rules corrected </h6></li>
            </ul>

            <ul>
               <li><h6>Action Page</h6></li>
               <ul>
                  <li>Taboola Campaign list not showing for Media Buyer role or Affiliate role</li>
                  <li>Bing CSV and overwrite rules corrected</li>
                  <li>Taboola CSV refactored</li>
               </ul>
            </ul>
            <ul>
               <li><h6>User settings</h6></li>
               <ul>
                  <li>All tab lists, should display only active or existing accounts/reports/tables</li>
               </ul>
            </ul>
            <ul>
               <li><h6>Admin role</h6></li>
               <ul>
                  <li>Report access by default</li>
               </ul>
            </ul>
            <ul>
               <li><h6>Outbrain reports (section and ad reports)</h6></li>
               <ul>
                  <li>TuneStats Sale Amount</li>
                  <ul>
                     <li>Includes Purchase, Add to Cart and Checkout</li>
                  </ul>
                  <li>Tune Conv sale amount</li>
                  <ul>
                     <li>Includes</li>
                  </ul>
               </ul>
               <li>
                  <h6>Daily Tune Cron update changed from 06:30 AM to 08:00 AM (1.5 hour later) and from 10:00 PM to
                     11:00 PM (1 hour later)</h6>
               </li>
            </ul>
         </p>
         <hr/>
         <p className="mt-3">
            <h4>AffsMedia Analytics v2.0.1.7</h4>
            <h6>Action page</h6>
            <ul>
               <li>Resizing</li>
               <li>Action rules enforced</li>
               <li>Outbrain campaigns sorted</li>
               <li>Outbrain csv upload confirmation includes campaign</li>
               <li>CSV upload,new confirmations (overwrite & merge)</li>
               <li>Regenerate reports. run sequentially only, removed all reports</li>
            </ul>
            <h6>Actions Log</h6>
            <ul>
               <li>Time to complete (TTC) column</li>
               <li>Filters, Report & selections for others</li>
               <li>Auto updates & cron jobs reported</li>
            </ul>
            <h6>Platform settings</h6>
            <ul>
               <li>Platform filter</li>
            </ul>
            <h6>Conversions report</h6>
            <ul>
               <li>Platform Filter</li>
               <li>Affiliate IDs column, display 3 values max + tooltip</li>
            </ul>
            <h6>Report Settings</h6>
            <ul>
               <li>Filters, report type, user names</li>
               <li>Affiliate IDs column, display 3 values max + tooltip</li>
               <li>Goal Name column displays All (not blank)</li>
               <li>Create/Edit, simplified tooltip</li>
            </ul>
            <h6>Report Sponsored settings</h6>
            <ul>
               <li>Create/Edit Report Ids menu selection</li>
            </ul>
            <h6>User Settings</h6>
            <ul>
               <li>Role Filter</li>
            </ul>
         </p>
         <hr/>
         <h6 className="mt-5">Feedback link: <a href="mailto:Daniel@affsmedia.com">Daniel@affsmedia.com</a></h6>
      </div>
   )
}
