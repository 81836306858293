import { httpClient } from "../../config";

export const fetchConversionGoals = async ({ recordId, isCreate, apiUrl }) => {
  try {
    const endpoint = recordId
      ? `${apiUrl}/tables/conversion_reports/report_goals/${recordId}`
      : `${apiUrl}/tables/conversion_reports/all_goals`;

    const response = await httpClient(endpoint);
    if (!response?.json?.success) {
      throw new Error("Failed to fetch goals");
    }

    const { goals } = response.json;
    return formatGoals({ goals, isCreate });
  } catch (error) {
    console.error(error);
    throw new Error("Failed to fetch processed goals.");
  }
};

export const formatGoals = ({ goals, isCreate }) => {
  const { selectedGoals, goalModifications } = extractGoalData(goals, isCreate);

  return {
    goalNames: extractGoalNames(goals),
    selectedGoals,
    goalModifications,
  };
};

const extractGoalNames = (goals) => goals.map(({ goal_name }) => goal_name);

const extractGoalData = (goals, isCreate) => {
  return goals.reduce((acc, { goal_name, is_selected, modified_name }) => {
    acc.selectedGoals[goal_name] = isCreate ? true : is_selected;
    acc.goalModifications[goal_name] = modified_name;
    return acc;
  },
  { selectedGoals: {}, goalModifications: {} });
};

export const updateFormState = ({ setValue, selectedGoals, goalModifications, goalNames }) => {
  const dataArray = goalNames.map((goal) => ({
    goal_name: goal,
    is_selected: selectedGoals[goal],
    modified_name: goalModifications[goal],
  }));

  setValue("conversions_goals", JSON.stringify(dataArray));
};
